<template>
    <nav class="navbar fixed-top navbar-dark navbar-expand-lg bg-dark">
        <div class="container">     
            <router-link to="/dashboard" class="nav-link navbar-brand" :class="{'active': page_current == 'dashboard'}">
                <img class="align-top" :src="logo" height="30" border="0">
            </router-link>
            <template v-if="platform == 'desktop'">
                <template v-if="pipe == 3">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item">
                            <router-link to="/dashboard" class="nav-link" :class="{'active': page_current == '' || page_current == 'dashboard'}"><i class="fas fa-home mr-2"></i>Página inicial</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/dashboard/events" class="nav-link" :class="{'active': page_current == 'events'}"><i class="fas fa-fire mr-2"></i>Eventos</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/dashboard/bets" class="nav-link" :class="{'active': page_current == 'bets'}"><i class="fas fa-receipt mr-2"></i>Apostas</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/dashboard/leagues" class="nav-link" :class="{'active': page_current == 'leagues'}"><i class="fas fa-trophy mr-2"></i>Ligas</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/dashboard/odds" class="nav-link" :class="{'active': page_current == 'odds'}"><i class="fas fa-exchange-alt fa-rotate-90 mr-2"></i>Cotações</router-link>
                        </li>
                        <li class="nav-item">
                            <div class="dropdown">
                                <a class="nav-link dropdown-toggle" href="javascript:;" id="dropdown-menu-more" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v mr-2"></i>Mais
                                    <div class="dropdown-menu" aria-labelledby="dropdown-menu-more">
                                        <router-link to="/dashboard/user" class="dropdown-item" :class="{'active': page_current == 'user'}"><i class="fas fa-user-plus mr-2"></i>Novo usuário</router-link>
                                        <router-link to="/dashboard/users" class="dropdown-item" :class="{'active': page_current == 'users'}"><i class="fas fa-users mr-2"></i>Listar usuários</router-link>
                                        <div class="dropdown-divider"></div>
                                        <router-link to="/dashboard/cash/general" class="dropdown-item" :class="{'active': page_current == 'general'}"><i class="fas fa-coins mr-2"></i>Caixa geral</router-link>
                                        <router-link to="/dashboard/cash/manager" class="dropdown-item" :class="{'active': page_current == 'manager'}"><i class="fas fa-coins mr-2"></i>Caixa gerente</router-link>
                                        <router-link to="/dashboard/cash/clients" class="dropdown-item" :class="{'active': page_current == 'clients'}"><i class="fas fa-coins mr-2"></i>Caixa clientes</router-link>
                                        <router-link to="/dashboard/cash/salesman" class="dropdown-item" :class="{'active': page_current == 'salesman'}"><i class="fas fa-coins mr-2"></i>Caixa vendedor</router-link>
                                        <router-link to="/dashboard/cash/salesman-by-manager" class="dropdown-item" :class="{'active': page_current == 'salesman-by-manager'}"><i class="fas fa-coins mr-2"></i>Caixa ven. por gerente</router-link>
                                        <div class="dropdown-divider"></div>
                                        <router-link to="/dashboard/rules-leagues" class="dropdown-item" :class="{'active': page_current == 'rules-leagues'}"><i class="fad fa-shield mr-2"></i>Regras por liga</router-link>
                                        <router-link to="/dashboard/rules-clients" class="dropdown-item" :class="{'active': page_current == 'rules-clients'}"><i class="fas fa-user mr-2"></i>Regras por clientes</router-link>
                                        <router-link to="/dashboard/rules-managers" class="dropdown-item" :class="{'active': page_current == 'rules-managers'}"><i class="fas fa-shield-alt mr-2"></i>Regras por gerente</router-link>
                                        <router-link to="/dashboard/rules-salesman" class="dropdown-item" :class="{'active': page_current == 'rules-salesman'}"><i class="fas fa-user-shield mr-2"></i>Regras por vendedor</router-link>
                                        <div class="dropdown-divider"></div>
                                        <router-link to="/dashboard/challenge" class="dropdown-item" :class="{'active': page_current == 'challenge'}"><i class="fad fa-badge-dollar mr-2"></i>Desafios</router-link>
                                        <router-link to="/dashboard/results" class="dropdown-item" :class="{'active': page_current == 'results'}"><i class="fas fa-poll-h mr-2"></i>Resultados</router-link>
                                        <!-- <router-link to="/dashboard/accumulated" class="dropdown-item" :class="{'active': page_current == 'accumulated'}"><i class="fas fa-sack-dollar mr-2"></i>Acumuladão</router-link> -->
                                        <router-link to="/dashboard/live-events" class="dropdown-item" :class="{'active': page_current == 'live-events'}"><i class="fas fa-signal-stream mr-2"></i>Eventos ao vivo</router-link>
                                        <div class="dropdown-divider"></div>
                                        <router-link to="/dashboard/transactions" class="dropdown-item" :class="{'active': page_current == 'transactions'}"><i class="fas fa-wallet mr-2"></i>Transações</router-link>
                                        <router-link to="/dashboard/logs" class="dropdown-item" :class="{'active': page_current == 'logs'}"><i class="fas fa-user-secret mr-2"></i>Logs do sistema</router-link>
                                        <router-link to="/dashboard/commission-standard" class="dropdown-item" :class="{'active': page_current == 'commission-standard'}"><i class="fas fa-percent mr-2"></i>Padrão de comissões</router-link>                                        
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" @click="futurePredictions"><i class="fas fa-chart-line mr-2"></i>Previsões</a>
                                        <router-link to="/dashboard/releases" class="dropdown-item" :class="{'active': page_current == 'releases'}"><i class="fad fa-envelope-open-dollar mr-2"></i>Lançamentos</router-link>
                                        <router-link to="/dashboard/configs" class="dropdown-item" :class="{'active': page_current == 'configs'}"><i class="fas fa-cog mr-2"></i>Configurações</router-link>
                                    </div>
                                </a>
                            </div>
                        </li>
                    </ul>
                </template>
                <template v-else>
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item">
                            <router-link to="/dashboard" class="nav-link" :class="{'active': page_current == '' || page_current == 'dashboard'}"><i class="fas fa-home mr-2"></i>Página inicial</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/dashboard/bets" class="nav-link" :class="{'active': page_current == 'bets'}"><i class="fas fa-receipt mr-2"></i>Apostas</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/dashboard/cash/general" class="nav-link" :class="{'active': page_current == 'general'}"><i class="fas fa-coins mr-2"></i>Caixa</router-link>
                        </li>
                        <li class="nav-item">
                            <div class="dropdown">
                                <a class="nav-link dropdown-toggle" href="javascript:;" id="dropdown-menu-more" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v mr-2"></i>Mais
                                    <div class="dropdown-menu" aria-labelledby="dropdown-menu-more">
                                        <router-link to="/dashboard/cash/salesman" class="dropdown-item" :class="{'active': page_current == 'salesman'}"><i class="fas fa-coins mr-2"></i>Caixa vendedor</router-link>
                                        <div class="dropdown-divider"></div>
                                        <router-link to="/dashboard/user" class="dropdown-item" :class="{'active': page_current == 'user'}"><i class="fas fa-user-plus mr-2"></i>Novo usuário</router-link>
                                        <router-link to="/dashboard/users" class="dropdown-item" :class="{'active': page_current == 'users'}"><i class="fas fa-users mr-2"></i>Listar usuários</router-link>
                                    </div>
                                </a>
                            </div>
                        </li>           
                    </ul>
                </template>
                <button class="btn btn-danger mr-3" v-if="pipe == 3" @click="futurePredictions">
                    <i class="fas fa-chart-line"></i>
                </button>
                <div class="dropdown">
                    <button class="btn btn-outline-warning my-2 my-sm-0 dropdown-toggle" id="dropdown-menu-account" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-user-circle mr-2"></i>Conta
                        <div class="dropdown-menu" aria-labelledby="dropdown-menu-account">
                            <h6 class="dropdown-header">
                                <div class="text-truncate" style="max-width: 120px;">Olá, {{user_name}}</div>
                            </h6>
                            <template v-if="pipe == 2">
                                <div class="dropdown-divider"></div>
                                <div class="dropdown-header">
                                    <i class="far fa-dollar-sign mr-2"></i>
                                    <div class="float-right">{{current_credit}}</div>
                                </div>
                            </template>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:;" @click="logout"><i class="fas fa-sign-out-alt mr-2"></i>Sair</a>
                        </div>
                    </button>
                </div>
            </template>
            <template v-else>
                <button class="btn btn-outline-warning my-2 my-sm-0" @click="sidebar = true">
                    <i class="fas fa-bars"></i>
                </button>
                <div class="sidebar" :class="{'sidebar-show': sidebar}">
                    <div class="sidebar-backdrop" @click="sidebar = false"></div>
                    <div class="sidebar-content">
                        <div class="list-group list-group-flush" @click="sidebar = false">
                            <div class="list-group-item list-dark-header bg-dark">
                                <div class="text-center">
                                    <i class="fad fa-user-circle display-4"></i>
                                    <p class="mb-2">Olá, {{user_name}}</p>
                                </div>
                                <div class="w-100 mt-3 mb-3" v-show="pipe == 2">
                                    <div class="row">
                                        <div class="col text-left text-white text-truncate"><i class="far fa-dollar-sign mr-2"></i>Crédito</div>
                                        <div class="col text-right text-white text-truncate">{{current_credit}}</div>
                                    </div>
                                </div>
                                <button class="btn btn-danger btn-block" @click="logout">
                                    <i class="fas fa-sign-out-alt mr-2"></i>Sair
                                </button>                           
                            </div>
                            <div class="list-group-item list-dark-header"><i class="fas fa-hashtag mr-2"></i>Principal</div>
                            <router-link to="/dashboard" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == '' || page_current == 'dashboard'}"><i class="fas fa-home mr-2"></i>Início</router-link>
                            <router-link to="/dashboard/events" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'events'}"><i class="fas fa-fire mr-2"></i>Eventos</router-link>
                            <router-link to="/dashboard/bets" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'bets'}"><i class="fas fa-receipt mr-2"></i>Apostas</router-link>
                            <router-link to="/dashboard/leagues" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'leagues'}"><i class="fas fa-trophy mr-2"></i>Ligas</router-link>
                            <router-link to="/dashboard/live-events" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'live-events'}"><i class="fas fa-signal-stream mr-2"></i>Ao vivo</router-link>
                            <a class="list-group-item list-group-item-action list-dark" v-if="pipe == 3" @click="futurePredictions"><i class="fas fa-chart-line mr-2"></i>Previsões</a>
                            <router-link to="/dashboard/results" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'results'}"><i class="fas fa-poll-h mr-2"></i>Resultados</router-link>
                            <router-link to="/dashboard/transactions" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'transactions'}"><i class="fas fa-wallet mr-2"></i>Transações</router-link>
                            <router-link to="/dashboard/releases" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'releases'}"><i class="fad fa-envelope-open-dollar mr-2"></i>Lançamentos</router-link>
                            <router-link to="/dashboard/configs" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'configs'}"><i class="fas fa-cog mr-2"></i>Configurações</router-link>
                            <router-link to="/dashboard/logs" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'logs'}"><i class="fas fa-user-secret mr-2"></i>Logs do sistema</router-link>
                            <router-link to="/dashboard/commission-standard" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'commission-standard'}"><i class="fas fa-percent mr-2"></i>Padrão de comissões</router-link>
                            <div class="list-group-item list-dark-header"><i class="fas fa-hashtag mr-2"></i>Usuários</div>
                            <router-link to="/dashboard/user" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'user'}"><i class="fas fa-user-plus mr-2"></i>Novo usuário</router-link>
                            <router-link to="/dashboard/users" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'users'}"><i class="fas fa-users mr-2"></i>Listar usuários</router-link>
                            <div class="list-group-item list-dark-header"><i class="fas fa-hashtag mr-2"></i>Financeiro</div>
                            <router-link to="/dashboard/cash/general" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'general'}"><i class="fas fa-coins mr-2"></i>Caixa geral</router-link>
                            <router-link to="/dashboard/cash/manager" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'manager'}"><i class="fas fa-coins mr-2"></i>Caixa gerente</router-link>
                            <router-link to="/dashboard/cash/clients" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'clients'}"><i class="fas fa-coins mr-2"></i>Caixa clientes</router-link>
                            <router-link to="/dashboard/cash/salesman" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'salesman'}"><i class="fas fa-coins mr-2"></i>Caixa vendedor</router-link>
                            <router-link to="/dashboard/cash/salesman-by-manager" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'salesman-by-manager'}"><i class="fas fa-coins mr-2"></i>Caixa ven. por gerente</router-link>
                            <div class="list-group-item list-dark-header" v-if="pipe == 3"><i class="fas fa-hashtag mr-2"></i>Controle de odds</div>
                            <router-link to="/dashboard/odds" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'odds'}"><i class="fas fa-exchange-alt fa-rotate-90 mr-2"></i>Cotações</router-link>
                            <router-link to="/dashboard/rules-leagues" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'rules-leagues'}"><i class="fad fa-shield mr-2"></i>Regras por liga</router-link>
                            <router-link to="/dashboard/rules-clients" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'rules-clients'}"><i class="fas fa-user mr-2"></i>Regras por cliente</router-link>
                            <router-link to="/dashboard/rules-managers" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'rules-managers'}"><i class="fas fa-shield-alt mr-2"></i>Regras por gerente</router-link>
                            <router-link to="/dashboard/rules-salesman" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'rules-salesman'}"><i class="fas fa-user-shield mr-2"></i>Regras por vendedor</router-link>
                            <div class="list-group-item list-dark-header" v-if="pipe == 3" ><i class="fas fa-hashtag mr-2"></i>Entretenimento</div>
                            <router-link to="/dashboard/challenge" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'challenge'}"><i class="fad fa-badge-dollar mr-2"></i>Desafios</router-link>
                            <!-- <router-link to="/dashboard/accumulated" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'accumulated'}"><i class="fas fa-sack-dollar mr-2"></i>Acumuladão</router-link> -->
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </nav>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    data() {
        return {
            pipe: 0,
            user_name: '',
            page_current: document.location.pathname.split('/').pop(),
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
				},
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
			}
        }
    },
    methods: {
        logout() {
            this.removeItem('x-token').then(() => {
                this.removeItem('auth').then(() => {
                    this.removeItem('firebase:pipe').then(() => {
                        window.location.href = '/';
                    });
                });
            });
        },
        removeItem(key) {
            return Promise.resolve().then(() => {
                window.localStorage.removeItem(key)
            });
        },
        futurePredictions() {

            Swal.fire({
                title: 'Previsões futuras',
                html: 'Aguarde... <br /> Esse processo pode demorar um pouco.',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                }
            });

            axios.get('https://api.betsnow.net/get-statistics').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        this.futurePredictionsHtml(response.data.events);
                    break;
                    default:
                        Swal.fire(
                            'Atenção!',
                            response.data.message,
                            'warning'
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'error'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'error'
                    );
                }
            });
        },
        futurePredictionsHtml(events) {

            let html = ``;

            for (let key in events) {
                let event = events[key];

                html += '<div class="row">';

                html += `<div class="col pt-2 pb-2 bg-dee2e6 text-truncate">
                    <span>${event.league.name}</span>
                </div>`;

                html += '<div class="w-100"></div>';

                for (const item of event.events) {
                    html += `<div class="col-12">
                        <div class="row border-top pt-3 mb-3">
                            <div class="col text-truncate text-center">
                                <div class="clearfix">
                                    <img src="${item.team_home.image}" border="0" alt="${item.team_home.name}" onerror="this.src='/images/shield_default.png'" width="32" height="32">
                                </div>
                                <span>${item.team_home.name}</span>
                                <div class="w-100">
                                    <small class="text-primary">${item.percents.home}</small>
                                </div>
                            </div>
                            <div class="col-auto text-center align-self-center">
                                <div class="w-100">
                                    <p class="m-0">
                                        <small class="text-danger">${item.date}</small>
                                    </p>
                                    <img class="align-middle" src="https://static.betsnow.net/images/f/versus_symbol.png" alt="X">
                                    <p class="m-0 pt-1">
                                        <small class="text-primary">${item.percents.draw}</small>
                                    </p>
                                </div>
                            </div>
                            <div class="col text-truncate text-center">
                                <div class="clearfix">
                                    <img src="${item.team_away.image}" border="0" alt="${item.team_away.name}" onerror="this.src='/images/shield_default.png'" width="32" height="32">
                                </div>
                                <span>${item.team_away.name}</span>
                                <div class="w-100">
                                    <small class="text-primary">${item.percents.away}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-100"></div>`;
                }

                html += '</div>';             
            }

            Swal.fire(
                'Previsões futuras',
                `<div class="swal-scroll-area">
                    ${html}
                </div>`
            );
        }
    },
    watch: {
        sidebar(value) {

            let html = document.getElementsByTagName('html')[0];

            if (value) {
                html.className += 'overflow-hidden';
            } else {
                html.classList.remove('overflow-hidden');
            }
        }
    },
    created() {

        try {
            
            const {name, pipe} = JSON.parse(window.localStorage.getItem('auth'));

            this.pipe = pipe;
            this.user_name = name;
        } catch(e) {
            this.pipe = 0;
            this.user_name = 'Anônimo';
        }

        window.addEventListener('popstate', (e) => {

            e.preventDefault();

            if (this.sidebar) {
                this.sidebar = false;
            }

            history.pushState(null, null, document.location.origin);
        });

        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.dropdown-toggle:after {
  border: none;
}
.card.no-border {
    border: none!important;
}
.sidebar {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1031;
    visibility: hidden;
    transition: visibility 0.3s;
}
.sidebar .sidebar-backdrop {
    width: 100vh;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.21176470588235294);
}
.sidebar .sidebar-content {
    width: 40vh;
    height: 100%;
    top: -1px;
    right: -40vh;
    position: absolute;
    background-color: #3b3b3b;
    transition: right 0.3s;
    -webkit-overflow-scrolling: touch;
}
.sidebar .sidebar-content .list-group {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}
.sidebar.sidebar-show {
    visibility: visible;
}
.sidebar.sidebar-show .sidebar-content {
    right: -1px;
}
.list-group-item.list-dark {
    color: #999;
    background-color: transparent;
}
.list-group-item.list-dark.active {
    color: #fff;
    border-color: rgba(0,0,0,.125);
}
.list-group-item.list-dark-header {
    color: #ffc107;
    border: none;
    background-color: #333;
}
</style>